<template>
    <van-row>
        <van-col v-if="isPc" :span="8"></van-col>
        <van-col :span="isPc ? 8 : 24">
            <van-form @submit="onSubmit" class="form-box">
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">人员类型统计</div>
                    <van-cell-group>
                        <van-field v-model="users.realNum" type="digit" label="园区实际人数" input-align="right" disabled class="form-title">
                            <template slot="button">人</template>
                        </van-field>
                        <van-field v-model="users.num" type="digit" label="本公司员工数" input-align="right" @change="changeUsers('num', users.num)">
                            <template slot="button">人</template>
                        </van-field>
                        <van-field v-model="users.supplier" type="digit" label="供应商" input-align="right" @change="changeUsers('supplier', users.supplier)">
                            <template slot="button">人</template>
                        </van-field>
                        <van-field v-model="users.inspect" type="digit" label="检验检疫" input-align="right" @change="changeUsers('inspect', users.inspect)">
                            <template slot="button">人</template>
                        </van-field>
                        <van-field v-model="users.other" type="digit" label="其他" input-align="right" @change="changeUsers('other', users.other)">
                            <template slot="button">人</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">车辆类型统计</div>
                    <van-cell-group>
                        <van-field v-model="cars.realNum" type="digit" label="园区车辆总数" input-align="right" disabled class="form-title">
                            <template slot="button">辆</template>
                        </van-field>
                        <van-field v-model="cars.meter42" type="digit" label="4米2" input-align="right" @change="changeCars('meter42', cars.meter42)">
                            <template slot="button">辆</template>
                        </van-field>
                        <van-field v-model="cars.meter29" type="digit" label="2米6" input-align="right" @change="changeCars('meter29', cars.meter29)">
                            <template slot="button">辆</template>
                        </van-field>
                        <van-field v-model="cars.ownCar" type="digit" label="自有车辆数" input-align="right" @change="changeCars('ownCar', cars.ownCar)">
                            <template slot="button">辆</template>
                        </van-field>
                        <van-field v-model="cars.visitorCar" type="digit" label="租赁车辆数" input-align="right" @change="changeCars('visitorCar', cars.visitorCar)">
                            <template slot="button">辆</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">应急库分析</div>
                    <van-cell-group>
                        <van-field v-model="emergency.totalArea" type="number" label="库房总面积" input-align="right" disabled class="form-title">
                            <template slot="button">平米</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">库房分类面积统计</div>
                        <van-field v-model="emergency.coldStorage" type="number" label="冷藏库" input-align="right" @change="changeEmergency('coldStorage', emergency.coldStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="emergency.freezerStorage" type="number" label="冷冻库" input-align="right" @change="changeEmergency('freezerStorage', emergency.freezerStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="emergency.ordinaryStorage" type="number" label="常温库" input-align="right" @change="changeEmergency('ordinaryStorage', emergency.ordinaryStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="emergency.otherStorage" type="number" label="其他库" input-align="right" @change="changeEmergency('otherStorage', emergency.otherStorage)">
                            <template slot="button">平米</template>
                        </van-field>

                        <van-field v-model="emergency.totalFoods" type="number" label="食品总量" input-align="right" disabled class="form-title">
                            <template slot="button">吨</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">食品细分分类</div>
                        <van-field v-model="emergency.cold" type="number" label="冷藏库" input-align="right" @change="changeEmergency('cold', emergency.cold)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="emergency.freezer" type="number" label="冷冻库" input-align="right" @change="changeEmergency('freezer', emergency.freezer)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="emergency.ordinary" type="number" label="常温库" input-align="right" @change="changeEmergency('ordinary', emergency.ordinary)">
                            <template slot="button">吨</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">周转库分析</div>
                    <van-cell-group>
                        <van-field v-model="turnover.totalArea" type="number" label="库房总面积" input-align="right" disabled class="form-title">
                            <template slot="button">平米</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">库房分类面积统计</div>
                        <van-field v-model="turnover.coldStorage" type="number" label="冷藏库" input-align="right" @change="changeTurnover('coldStorage', turnover.coldStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="turnover.freezerStorage" type="number" label="冷冻库" input-align="right" @change="changeTurnover('freezerStorage', turnover.freezerStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="turnover.ordinaryStorage" type="number" label="常温库" input-align="right" @change="changeTurnover('ordinaryStorage', turnover.ordinaryStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="turnover.otherStorage" type="number" label="其他库" input-align="right" @change="changeTurnover('otherStorage', turnover.otherStorage)">
                            <template slot="button">平米</template>
                        </van-field>

                        <van-field v-model="turnover.totalFoods" type="number" label="食品总量" input-align="right" disabled class="form-title">
                            <template slot="button">吨</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">食品细分分类</div>
                        <van-field v-model="turnover.cold" type="number" label="冷藏库" input-align="right" @change="changeTurnover('cold', turnover.cold)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="turnover.freezer" type="number" label="冷冻库" input-align="right" @change="changeTurnover('freezer', turnover.freezer)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="turnover.ordinary" type="number" label="常温库" input-align="right" @change="changeTurnover('ordinary', turnover.ordinary)">
                            <template slot="button">吨</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">储备库分析</div>
                    <van-cell-group>
                        <van-field v-model="reserve.totalArea" type="number" label="库房总面积" input-align="right" disabled class="form-title">
                            <template slot="button">平米</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">库房分类面积统计</div>
                        <van-field v-model="reserve.coldStorage" type="number" label="冷藏库" input-align="right" @change="changeReserve('coldStorage', reserve.coldStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="reserve.freezerStorage" type="number" label="冷冻库" input-align="right" @change="changeReserve('freezerStorage', reserve.freezerStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="reserve.ordinaryStorage" type="number" label="常温库" input-align="right" @change="changeReserve('ordinaryStorage', reserve.ordinaryStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="reserve.otherStorage" type="number" label="其他库" input-align="right" @change="changeReserve('otherStorage', reserve.otherStorage)">
                            <template slot="button">平米</template>
                        </van-field>

                        <van-field v-model="reserve.totalFoods" type="number" label="食品总量" input-align="right" disabled class="form-title">
                            <template slot="button">吨</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">食品细分分类</div>
                        <van-field v-model="reserve.cold" type="number" label="冷藏库" input-align="right" @change="changeReserve('cold', reserve.cold)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="reserve.freezer" type="number" label="冷冻库" input-align="right" @change="changeReserve('freezer', reserve.freezer)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="reserve.ordinary" type="number" label="常温库" input-align="right" @change="changeReserve('ordinary', reserve.ordinary)">
                            <template slot="button">吨</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:6px;font-size:18px">前置库分析</div>
                    <van-cell-group>
                        <van-field v-model="pretank.totalArea" type="number" label="库房总面积" input-align="right" disabled class="form-title">
                            <template slot="button">平米</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">库房分类面积统计</div>
                        <van-field v-model="pretank.coldStorage" type="number" label="冷藏库" input-align="right" @change="changePretank('coldStorage', pretank.coldStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="pretank.freezerStorage" type="number" label="冷冻库" input-align="right" @change="changePretank('freezerStorage', pretank.freezerStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="pretank.ordinaryStorage" type="number" label="常温库" input-align="right" @change="changePretank('ordinaryStorage', pretank.ordinaryStorage)">
                            <template slot="button">平米</template>
                        </van-field>
                        <van-field v-model="pretank.otherStorage" type="number" label="其他库" input-align="right" @change="changePretank('otherStorage', pretank.otherStorage)">
                            <template slot="button">平米</template>
                        </van-field>

                        <van-field v-model="pretank.totalFoods" type="number" label="食品总量" input-align="right" disabled class="form-title">
                            <template slot="button">吨</template>
                        </van-field>
                        <div class="van-cell van-field" style="padding-left:10px;font-size:16px">食品细分分类</div>
                        <van-field v-model="pretank.cold" type="number" label="冷藏库" input-align="right" @change="changePretank('cold', pretank.cold)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="pretank.freezer" type="number" label="冷冻库" input-align="right" @change="changePretank('freezer', pretank.freezer)">
                            <template slot="button">吨</template>
                        </van-field>
                        <van-field v-model="pretank.ordinary" type="number" label="常温库" input-align="right" @change="changePretank('ordinary', pretank.ordinary)">
                            <template slot="button">吨</template>
                        </van-field>
                    </van-cell-group>
                </template>
                <van-cell title="安全运行起始日期" :value="dateFormat" @click="dialog = true" class="cell-date"></van-cell>
                <van-calendar v-model="dialog" @confirm="onConfirm" :default-date="date" :min-date="minDate" :max-date="maxDate"></van-calendar>
                <div class="form-button" :style="isPc ? `width:33.33%;` : 'width:100%;'">
                    <van-button round block type="info" native-type="submit" :disabled="isSave ? false : true">保存</van-button>
                </div>
            </van-form>
        </van-col>
        <van-col v-if="isPc" :span="8"></van-col>

        
        <!-- <div style="margin: 16px;" class="form-button">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div> -->
    </van-row>
</template>
<style>
.van-field--disabled > .van-field__label > span{
    color: #323232 !important;
}
.van-field--disabled > .van-field__value > .van-field__body > .van-field__control:disabled{
    color: #323232 !important;
    -webkit-text-fill-color: #323232 !important
}
.cell-date {
    font-weight: bold;
    padding-left: 6px;
    font-size:18px;
}
/* .cell-date > .van-cell__title > span{
    font-size:18px;
} */
.cell-date > .van-cell__value {
    color: #323232;
}
.form-box {
    /* width: 100%; */
    min-height: 100vh;
    padding-bottom: 56px !important;
    position: relative;
}
.form-button {
    margin-top:16px;
    /* background: white; */
    position: fixed; /* 将按钮设置为固定定位 */  
    bottom: 0; /* 设置按钮距离底部的距离 */
    /* position: absolute;
    bottom: 0; */
}
.form-title {
    padding-left: 10px;
    font-size:16px;
    font-weight:bold;
}
.van-popup {
    position: absolute;
    max-height: 60vh;
}
.van-notify {  
    position: fixed !important;  
    top: 0 !important;  
    left: 0 !important;  
    right: 0 !important;  
    z-index: 9999 !important;  
}
</style>
<script>
    export default {
        name: 'center',
        components: {
        },
        props: {},
        data() {
            return {
                isSave: false, // 是否可以保存
                isPc: false, // 是否pc端
                date: new Date(), // 日期
                dateFormat: '', // 日期
                minDate: new Date(2000, 0, 1),
                maxDate: new Date(2099, 0, 1),
                list: [], // 列表数据
                dialog: false, // 日期组件
                users: {
                    realNum: 0, //园区实际人数
                    num: 0, // 本公司员工数
                    supplier: 0, // 供应商
                    inspect: 0, // 检验检疫
                    other: 0, // 其他
                },
                cars: {
                    realNum: 0, // 园区车辆总数
                    meter42: 0, // 4米2
                    meter29: 0, // 2米9
                    ownCar: 0, // 自有车辆数
                    visitorCar: 0, // 访客车辆数
                },
                // 应急库
                emergency: {
                    totalArea: 0, // 总面积
                    coldStorage: 0, // 冷藏库
                    freezerStorage: 0, // 冷冻库
                    ordinaryStorage: 0, // 常温库
                    otherStorage: 0, // 其他库
                    totalFoods: 0, // 食品总量
                    cold: 0, // 冷冻
                    freezer: 0, // 冷藏
                    ordinary: 0, // 常温
                },
                // 周转库
                turnover: {
                    totalArea: 0, // 总面积
                    coldStorage: 0, // 冷藏库
                    freezerStorage: 0, // 冷冻库
                    ordinaryStorage: 0, // 常温库
                    otherStorage: 0, // 其他库
                    totalFoods: 0, // 食品总量
                    cold: 0, // 冷冻
                    freezer: 0, // 冷藏
                    ordinary: 0, // 常温
                },
                // 储备库
                reserve: {
                    totalArea: 0, // 总面积
                    coldStorage: 0, // 冷藏库
                    freezerStorage: 0, // 冷冻库
                    ordinaryStorage: 0, // 常温库
                    otherStorage: 0, // 其他库
                    totalFoods: 0, // 食品总量
                    cold: 0, // 冷冻
                    freezer: 0, // 冷藏
                    ordinary: 0, // 常温
                },
                // 前置库
                pretank: {
                    totalArea: 0, // 总面积
                    coldStorage: 0, // 冷藏库
                    freezerStorage: 0, // 冷冻库
                    ordinaryStorage: 0, // 常温库
                    otherStorage: 0, // 其他库
                    totalFoods: 0, // 食品总量
                    cold: 0, // 冷冻
                    freezer: 0, // 冷藏
                    ordinary: 0, // 常温
                },
            }
        },
        
        computed: {
        },
        watch: {
        },
        methods: {
            formatDate(date) {
                // console.log(date)
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                var res_month = month >= 1 && month <= 9 ? '0' + month : month;
                var res_day = day >= 0 && day <= 9 ? '0' + day : day;
                var value = year + '-' + res_month + '-' + res_day;
                // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                return value;
            },
            onConfirm(date) {
                this.dialog = false;
                this.dateFormat = this.formatDate(date);
            },
            changeUsers(prop, val) {
                // console.log(prop,val,(this.users.num + this.users.supplier + this.users.inspect + this.users.other))
                this.users[prop] = val ? val : 0;
                this.users.realNum = parseInt((parseInt(this.users.num) + parseInt(this.users.supplier) + parseInt(this.users.inspect) + parseInt(this.users.other)).toFixed(2));
            },
            changeCars(prop, val) {
                this.cars[prop] = val ? val : 0;
                this.cars.realNum = parseInt((parseInt(this.cars.meter42) + parseInt(this.cars.meter29)).toFixed(2));
            },
            changeEmergency(prop, val) {
                this.emergency[prop] = val ? val : 0;
                this.emergency.totalArea = parseFloat((parseFloat(this.emergency.coldStorage) + parseFloat(this.emergency.freezerStorage) + parseFloat(this.emergency.ordinaryStorage) + parseFloat(this.emergency.otherStorage)).toFixed(2));
                this.emergency.totalFoods = parseFloat((parseFloat(this.emergency.cold) + parseFloat(this.emergency.freezer) + parseFloat(this.emergency.ordinary)).toFixed(2));
            },
            changeTurnover(prop, val) {
                this.turnover[prop] = val ? val : 0;
                this.turnover.totalArea = parseFloat((parseFloat(this.turnover.coldStorage) + parseFloat(this.turnover.freezerStorage) + parseFloat(this.turnover.ordinaryStorage) + parseFloat(this.turnover.otherStorage)).toFixed(2));
                this.turnover.totalFoods = parseFloat((parseFloat(this.turnover.cold) + parseFloat(this.turnover.freezer) + parseFloat(this.turnover.ordinary)).toFixed(2));
            },
            changeReserve(prop, val) {
                this.reserve[prop] = val ? val : 0;
                this.reserve.totalArea = parseFloat((parseFloat(this.reserve.coldStorage) + parseFloat(this.reserve.freezerStorage) + parseFloat(this.reserve.ordinaryStorage) + parseFloat(this.reserve.otherStorage)).toFixed(2));
                this.reserve.totalFoods = parseFloat((parseFloat(this.reserve.cold) + parseFloat(this.reserve.freezer) + parseFloat(this.reserve.ordinary)).toFixed(2));
            },
            changePretank(prop, val) {
                this.pretank[prop] = val ? val : 0;
                this.pretank.totalArea = parseFloat((parseFloat(this.pretank.coldStorage) + parseFloat(this.pretank.freezerStorage) + parseFloat(this.pretank.ordinaryStorage) + parseFloat(this.pretank.otherStorage)).toFixed(2));
                this.pretank.totalFoods = parseFloat((parseFloat(this.pretank.cold) + parseFloat(this.pretank.freezer) + parseFloat(this.pretank.ordinary)).toFixed(2));
            },
            async getInfo() {
                var info = {
                    a:1
                }
                var url = '/api/zhanjiang_fw/List';

                await this.$axios.post(url, this.$qs.stringify(info)).then(async res => {
                    if(res.data.code == 1) {
                        console.log(res)
                        this.isSave = true;
                        var list = res.data.list;
                        const users = {
                            100: 'num',  
                            101: 'supplier',  
                            102: 'inspect',  
                            103: 'other'  
                        }
                        const cars = {
                            200: 'meter42',  
                            201: 'meter29',  
                            202: 'ownCar',  
                            203: 'visitorCar'  
                        }
                        const emergency = {
                            300: 'coldStorage',
                            301: 'freezerStorage',
                            302: 'ordinaryStorage',
                            303: 'otherStorage',
                            304: 'cold',
                            305: 'freezer',
                            306: 'ordinary',
                        }
                        const turnover = {
                            400: 'coldStorage',
                            401: 'freezerStorage',
                            402: 'ordinaryStorage',
                            403: 'otherStorage',
                            404: 'cold',
                            405: 'freezer',
                            406: 'ordinary',
                        }
                        const reserve = {
                            500: 'coldStorage',
                            501: 'freezerStorage',
                            502: 'ordinaryStorage',
                            503: 'otherStorage',
                            504: 'cold',
                            505: 'freezer',
                            506: 'ordinary',
                        }
                        const pretank = {
                            600: 'coldStorage',
                            601: 'freezerStorage',
                            602: 'ordinaryStorage',
                            603: 'otherStorage',
                            604: 'cold',
                            605: 'freezer',
                            606: 'ordinary',
                        }
                        for(var item of list) {
                            if (users.hasOwnProperty(item.id)) {  
                                this.users[users[item.id]] = parseInt(item.val, 10);  
                            }
                            if (cars.hasOwnProperty(item.id)) {  
                                this.cars[cars[item.id]] = parseInt(item.val, 10);  
                            }
                            if (emergency.hasOwnProperty(item.id)) {  
                                this.emergency[emergency[item.id]] = parseFloat(item.val, 10);  
                            }
                            if (turnover.hasOwnProperty(item.id)) {  
                                this.turnover[turnover[item.id]] = parseFloat(item.val, 10);  
                            }
                            if (reserve.hasOwnProperty(item.id)) {  
                                this.reserve[reserve[item.id]] = parseFloat(item.val, 10);  
                            }
                            if (pretank.hasOwnProperty(item.id)) {  
                                this.pretank[pretank[item.id]] = parseFloat(item.val, 10);  
                            }
                            if (item.id == 999) {
                                this.dateFormat = item.description;
                                this.date = new Date(item.description)
                            }
                        }
                        this.users.realNum = parseInt((this.users.num + this.users.supplier + this.users.inspect + this.users.other).toFixed(2));
                        this.cars.realNum = parseInt((this.cars.meter42 + this.cars.meter29).toFixed(2));
                        this.emergency.totalArea = parseFloat((this.emergency.coldStorage + this.emergency.freezerStorage + this.emergency.ordinaryStorage + this.emergency.otherStorage).toFixed(2));
                        this.emergency.totalFoods = parseFloat((this.emergency.cold + this.emergency.freezer + this.emergency.ordinary).toFixed(2));
                        this.turnover.totalArea = parseFloat((this.turnover.coldStorage + this.turnover.freezerStorage + this.turnover.ordinaryStorage + this.turnover.otherStorage).toFixed(2));
                        this.turnover.totalFoods = parseFloat((this.turnover.cold + this.turnover.freezer + this.turnover.ordinary).toFixed(2));
                        this.reserve.totalArea = parseFloat((this.reserve.coldStorage + this.reserve.freezerStorage + this.reserve.ordinaryStorage + this.reserve.otherStorage).toFixed(2));
                        this.reserve.totalFoods = parseFloat((this.reserve.cold + this.reserve.freezer + this.reserve.ordinary).toFixed(2));
                        this.pretank.totalArea = parseFloat((this.pretank.coldStorage + this.pretank.freezerStorage + this.pretank.ordinaryStorage + this.pretank.otherStorage).toFixed(2));
                        this.pretank.totalFoods = parseFloat((this.pretank.cold + this.pretank.freezer + this.pretank.ordinary).toFixed(2));
                        this.list = list;
                    } else {
                        this.isSave = false;
                        this.$notify({
                            type: 'danger',
                            message: res.data.msg
                        });
                    }
                })
            },
            // 保存
            async onSubmit() {
                const users = {
                    100: 'num',  
                    101: 'supplier',  
                    102: 'inspect',  
                    103: 'other'  
                }
                const cars = {
                    200: 'meter42',  
                    201: 'meter29',  
                    202: 'ownCar',  
                    203: 'visitorCar'  
                }
                const emergency = {
                    300: 'coldStorage',
                    301: 'freezerStorage',
                    302: 'ordinaryStorage',
                    303: 'otherStorage',
                    304: 'cold',
                    305: 'freezer',
                    306: 'ordinary',
                }
                const turnover = {
                    400: 'coldStorage',
                    401: 'freezerStorage',
                    402: 'ordinaryStorage',
                    403: 'otherStorage',
                    404: 'cold',
                    405: 'freezer',
                    406: 'ordinary',
                }
                const reserve = {
                    500: 'coldStorage',
                    501: 'freezerStorage',
                    502: 'ordinaryStorage',
                    503: 'otherStorage',
                    504: 'cold',
                    505: 'freezer',
                    506: 'ordinary',
                }
                const pretank = {
                    600: 'coldStorage',
                    601: 'freezerStorage',
                    602: 'ordinaryStorage',
                    603: 'otherStorage',
                    604: 'cold',
                    605: 'freezer',
                    606: 'ordinary',
                }

                for(let item of this.list) {
                    if (users.hasOwnProperty(item.id)) {  
                        item.val = this.users[users[item.id]];
                    }
                    if (cars.hasOwnProperty(item.id)) {
                        item.val = this.cars[cars[item.id]];
                    }
                    if (emergency.hasOwnProperty(item.id)) {  
                        item.val = this.emergency[emergency[item.id]];
                    }
                    if (turnover.hasOwnProperty(item.id)) {  
                        item.val = this.turnover[turnover[item.id]];
                    }
                    if (reserve.hasOwnProperty(item.id)) {  
                        item.val = this.reserve[reserve[item.id]];
                    }
                    if (pretank.hasOwnProperty(item.id)) {  
                        item.val = this.pretank[pretank[item.id]];
                    }
                    if (item.id == 999) {
                        item.description = this.dateFormat;
                    }
                }
                var newArr = [];
                for(let item of this.list) {
                    newArr.push({
                        id: item.id,
                        val: item.val,
                        description: item.description
                    })
                }
                console.log(newArr)
                var headerInfo = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                var url = '/api/zhanjiang_fw/Update';

                await this.$axios.post(url, newArr,headerInfo).then(async res => {
                    if(res.data.code == 1) {
                        this.$notify({
                            type: 'success',
                            message: '保存成功！'
                        });
                        this.getInfo();
                    } else {
                        this.$notify({
                            type: 'danger',
                            message: '保存失败！'
                        });
                    }
                })
            },
        },
        async created() {
            const userAgent = navigator.userAgent;  
            const isMobile = /Mobile|Android|iOS/.test(userAgent);  
            if (isMobile) {  
                console.log('是移动设备')
                this.isPc = false;
                // 是移动设备  
                // 执行相应的逻辑  
            } else {
                console.log('是PC设备  ')
                this.isPc = true;
                // 是PC设备  
                // 执行相应的逻辑  
            }
            await this.getInfo();
            // this.getInfo();
            // console.log(this)
        },
        destroyed() {
        },
        mounted() {

        }
    }
</script>